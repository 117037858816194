.footer {
	font-family: 'roboto';
	font-size: 12px;
	line-height: 24px;
	color: white;
	background-image: linear-gradient(45deg,#A7CFF2 30%,#99B1BF 20%);
}

.footerBackgroundColor {
	/*background-color: #120F26;*/
}

.footerPadding {
	padding-top: 6em;
	padding-bottom: 3em;
}

.logo {
	color: white;
	font-size: 32px;
	line-height: 110px;
	font-family: 'roboto';
}
.orange {
	color: #ADC5D9;
}

.separation-border {

}

.color-white {
	color: white;
}

.whiteLink {
	color: white;
	cursor: pointer;
}
.whiteLink:hover {
	color: #ffa500;
	text-decoration: none;
}

.copyright {
	/*margin-bottom: 100px;*/
	margin-top: 35px;
}
