
.cta-s {
    background-color: #6abdd1;
    padding: 2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.cta-button{
    background-color: #91e08a;
    color: black;
}

.blog-list{
    margin-top: 1rem;
    margin-bottom: 1rem;

}