
.landingBackground {
    background-image: url('./../../assets/images/road.jpg');
    background-position: center;
    background-size: cover;
}
.orangeBackground {
    background-color:#99B1BF;
}

.jumbo-custom {
    color: white;
    margin-bottom: 0;
    background: linear-gradient(45deg, rgba(242,135,5, 0.50) 25%, rgba(242,159,5,0.0) 25%);
    background-size: cover;

    /*background: rgba(204, 204, 204, 0.5);*/
    /*z-index: -2;*/
    /*opacity: 0.4;*/
}

.container-custom {
    height: 60vh;
}

.rounded-button {
    border-radius: 40px;
    background-color: white;
    padding: 10px 27px;
    font-family: 'roboto';
    font-size: 12px;
    font-weight: 500;
    color: #333;
    border-color: #ccc;
}
.rounded-button:hover {
    background-color: #ececf6;
}

.padding-top-100 {
    padding-top: 100p4;
}

.home-header {
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    font-size: 3rem;
}
