.padding-bottom {
    padding-Bottom: 100px;
}

.background-color {
    background-color: #f6f6f6;
}

.section-padding {
    padding-top: 100px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-top-50 {
    padding-top: 50px;
}

.box {
    border-bottom: 1px solid #ededed;
    padding-bottom: 85px;
}
.leftBox {
    border-right: 1px solid #ededed;
}
.bottomBox {
    padding-top: 80px;
    border-bottom: none;
}

.right-offset-40 {
    padding-right: 40px;
}
.left-offset-40 {
    padding-left: 40px;
}

.media-text {
    color: #6d6d6d;
    font-family: 'roboto';
    font-size: 14px;
    margin: 0 0 10px;
}

.custom-bottom {
    position: absolute;
    bottom: 30px;
}

@media only screen and (max-width: 768px) {
    .box {
        border-bottom: none;
    }
    .leftBox {
        border-right: none;
    }
    .bottomBox {
        border-bottom: none;
        padding-top: 0;
    }

    .right-offset-40 {
        padding-right: 15px;
    }

    .left-offset-40 {
        padding-left: 15px;
    }
}

